import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vant from "vant"; // 整体导入vant组件库
import "vant/lib/index.css"; // 引入vant样式表
import "amfe-flexible"; //rem转化的工具
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

createApp(App).use(router).use(Vant).use(ElementPlus).mount('#app')

router.beforeEach((to, from, next) => {
  // 设置页面标题
  if (to.meta.title) {
    document.title = to.meta.title + ' - 钟公庙街道老年大学学习平台';
  } else {
    document.title = '钟公庙街道老年大学学习平台';
  }
  next();
});