<template>
  <!-- 底部导航 -->
  <div class="botbar">
    <div class="botInfo">
      <router-link to="/" class="detail">
        <img src="../../static/images/botbar/botbar1_show.png" v-if="message.type == 1">
        <img src="../../static/images/botbar/botbar1.png" v-else>
        <div class="name" :class="message.type == 1 ? 'nameShow' : ''">首页</div>
      </router-link>
      <router-link to="/CurList" class="detail">
        <img src="../../static/images/botbar/botbar2_show.png" v-if="message.type == 2">
        <img src="../../static/images/botbar/botbar2.png" v-else>
        <div class="name" :class="message.type == 2 ? 'nameShow' : ''">课程</div>
      </router-link>
      <router-link to="/MesList" class="detail">
        <img src="../../static/images/botbar/botbar3_show.png" v-if="message.type == 3">
        <img src="../../static/images/botbar/botbar3.png" v-else>
        <div class="name" :class="message.type == 3 ? 'nameShow' : ''">消息</div>
      </router-link>
      <router-link to="/My" class="detail">
        <img src="../../static/images/botbar/botbar4_show.png" v-if="message.type == 4">
        <img src="../../static/images/botbar/botbar4.png" v-else>
        <div class="name" :class="message.type == 4 ? 'nameShow' : ''">我的</div>
      </router-link>
    </div>
  </div>
  <div class="botbarCover"></div>
</template>

<script>
export default {
  // name: '写跟进',
  props: {
    message: Object,
  },
  data () {
    return {

    }
  },
  created () {//页面渲染之前

  },
  methods: {

  },
}
</script>

<style scoped>
.botbar {
  padding-bottom: 60px;
  background: #fff;
  border-top: 2px solid #E5E6EB;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 162px;
  z-index: 5;
}

.botbarCover {
  height: 162px;
}

.botInfo {
  display: flex;
}

.detail {
  flex: 1;
}

.detail img {
  width: 40px;
  height: 40px;
  margin: 12px auto 8px;
}

.detail .name {
  text-align: center;
  font-size: 24px;
  color: rgba(0, 0, 0, .4);
  line-height: 28px;
}

.detail .nameShow {
  color: #C4271C;
}
</style>