<template>
  <div class="contain">
    <div class="one">
      <router-link to="/CurList" class="search" style="position: sticky;z-index: 5;top: 0;display: black;">
        <img src="../../static/images/search.png" class="left">
        <div class="middle"><input type="text" placeholder="请输入课程名称搜索" readonly></div>
        <div class="right">搜索</div>
      </router-link>
      <van-swipe class="indexSwiper" :autoplay="5000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in banner" :key="index + 'ban'">
          <a :href="item.url" class="swiperImg"><img :src="item.image" alt=""></a>
        </van-swipe-item>
      </van-swipe>
      <div class="notice">
        <img src="../../static/images/notice.png" class="left">
        <div class="right">
          <van-swipe class="noticeSwiper" :autoplay="3000" vertical :touchable="false" :show-indicators="false">
            <van-swipe-item v-for="(item, index) in notice" :key="index + 'not'">
              <router-link :to="'/MesDetail?id=' + item.id" class="swiperText">{{ item.title }}</router-link>
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
    </div>
    <div class="two">
      <div class="left">正在报名 {{ ssss }}</div>
      <router-link to="/CurList" class="right">查看更多<img src="../../static/images/right.png" alt=""></router-link>
    </div>
    <div @scroll="onScroll" class="three" id="allList">
      <router-link :to="'/CurDetail?id=' + item.id" class="curCard" v-for="(item, index) in list" :key="index + 'list'">
        <div class="imgs">
          <img :src="item.course.image" alt="">
        </div>
        <div class="status" v-if="status == 1">
          <img src="../../static/images/notStart.png" alt="">
          <div class="staText">待报名</div>
        </div>
        <div class="status" v-if="status == 2">
          <img src="../../static/images/ing.png" alt="">
          <div class="staText">报名中</div>
        </div>
        <div class="title">{{ item.title }}</div>
        <div class="time">
          <img src="../../static/images/time.png" class="left">
          <div class="right">{{ item.sign_up_start_time }} ~ {{ item.sign_up_end_time }}</div>
        </div>
        <div class="time">
          <img src="../../static/images/address.png" class="left">
          <div class="right">{{ item.address }}</div>
        </div>
        <div class="price">
          <img src="../../static/images/price.png" class="left">
          <div class="right">报名费用：<span>{{ item.cost }}元</span></div>
        </div>
      </router-link>
    </div>
    <!-- 底部导航 -->
    <botbar :message="message"></botbar>
  </div>
</template>
<script>
import botbar from "../components/Botbar"
import http from "../http/api";
import { showToast, showLoadingToast, closeToast } from 'vant';
export default {
  // name: '空白页用于跳转',
  components: {
    botbar
  },
  data () {
    return {
      message: {
        type: 1,
      },
      banner: [],//轮播图
      notice: [],//通知
      status: 1,// 1 课程未开始报名 2 课程开始报名 3 课程已结束
      list: [],
    };
  },

  created () {//页面渲染之前
    
  },
  mounted () {//页面渲染之后
    let that = this;
    // 获取轮播图
    http.post("/home/slides/read", {}).then(res => {
      if (res.code != 1) {
        that.banner = [];
      } else {
        that.banner = res.data;
      }
    })
    // 获取通知 
    http.post("/user/message/index", {}).then(res => {
      if (res.code != 1) {
        that.notice = [];
      } else {
        that.notice = res.data.data;
      }
    })
    // 获取首页课程
    http.post("/home/index/course_sign_up_list", {
      status: 1,
      index: 1,
    }).then(res => {
      if (res.code != 1) {
        that.list = [];
      } else {
        that.list = res.data;
      }
    })
    if(localStorage.getItem("openid")){
      console.log("有openid")
    }else{
      let code = that.$route.query.code;
      if (code == false || code == "false" || !code) {//缓存没有openid且路径没有code
        let url = encodeURIComponent(window.location.hostname);
        http.post("/user/public/get_appid",{}).then(res=>{
          window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + res.data.appid + '&redirect_uri=https://' + url + '&response_type=code&scope=snsapi_base&state=STATE&agentid=AGENTID#wechat_redirect';
        })
      } else {
        http.get("/home/index/get_openid?code="+code,{}).then(res=>{
          localStorage.setItem("openid",res.data.openid);
        })
      }
    }
  },
  activated () {
    if (this.saveScrollTop > 0) {
      this.$nextTick(() => {
        document.getElementById("allList").scrollTop = this.saveScrollTop;
      });
    }
  },
  methods: {//事件
    // 记录滚动条位置
    onScroll (event) {
      this.saveScrollTop = event.target.scrollTop;
    },
    // 加载更多
    loadMore () {
      this.loading = false;//关闭
      this.finished = true;//已全部加载
    },
  },
};
</script>

<style scoped>
.contain {}

.one {
  overflow: hidden;
  height: 520px;
  background: linear-gradient(180deg, rgba(247, 247, 247, 0) 52%, #F7F7F7 100%), linear-gradient(128deg, #FFFFFF 4%, rgba(255, 222, 222, 0.4) 28%, rgba(255, 182, 182, 0.46) 57%, #FFEDED 88%);
}

.indexSwiper {
  width: 702px;
  height: 300px;
  margin: 22px auto;
  border-radius: 20px;
}

.indexSwiper .swiperImg {
  display: block;
  width: 100%;
  height: 100%;
}

.indexSwiper .swiperImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.notice {
  width: 702px;
  height: 64px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  background: #fff;
  margin: 22px auto 0;
  overflow: hidden;
}

.notice .left {
  width: 40px;
  height: 40px;
  margin: 0 12px 0 16px;
}

.notice .right {
  flex: 1;
  margin-right: 30px;
}

.notice .right .noticeSwiper {
  height: 64px;
}

.notice .right .noticeSwiper .swiperText {
  font-size: 30px;
  line-height: 64px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-wrap: break-word;
}

.two {
  width: 702px;
  margin: 42px auto 32px;
  display: flex;
  justify-content: space-between;
}

.two .left {
  font-size: 48px;
  line-height: 48px;
  font-weight: 600;
}

.two .right {
  display: flex;
  align-items: center;
  height: 48px;
  font-size: 28px;
  color: #666;
}

.two .right img {
  width: 24px;
  height: 24px;
  margin-left: 4px;
}

.three {
  flex: 1;
  overflow-y: auto;
}
</style>
