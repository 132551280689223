import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomeView.vue'),
    meta: {
      title: '首页',
      keepAlive: true,
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/Login/Login.vue'),
    meta: {
      title: '',
    }
  },
  {
    path: '/OtherLogin',
    name: 'OtherLogin',
    component: () => import('../views/Login/OtherLogin.vue'),
    meta: {
      title: '',
    }
  },
  {
    path: '/Register',
    name: 'Register',
    component: () => import('../views/Login/Register.vue'),
    meta: {
      title: '',
    }
  },
  {
    path: '/Forget',
    name: 'Forget',
    component: () => import('../views/Login/Forget.vue'),
    meta: {
      title: '忘记密码',
    }
  },
  {
    path: '/InproveUser',
    name: 'InproveUser',
    component: () => import('../views/Login/InproveUser.vue'),
    meta: {
      title: '',
    }
  },
  {
    path: '/AddForm',
    name: 'AddForm',
    component: () => import('../views/Login/AddForm.vue'),
    meta: {
      title: '身体状况说明表',
    }
  },
  {
    path: '/CurList',
    name: 'CurList',
    component: () => import('../views/Curriculum/CurList.vue'),
    meta: {
      title: '课程',
      keepAlive: true,
    }
  },
  {
    path: '/History',
    name: 'History',
    component: () => import('../views/Curriculum/History.vue'),
    meta: {
      title: '课程',
      keepAlive: true,
    }
  },
  {
    path: '/CurDetail',
    name: 'CurDetail',
    component: () => import('../views/Curriculum/CurDetail.vue'),
    meta: {
      title: '课程详情',
    }
  },
  {
    path: '/SignUp',
    name: 'SignUp',
    component: () => import('../views/Curriculum/SignUp.vue'),
    meta: {
      title: '课程确认报名',
    }
  },
  {
    path: '/MesList',
    name: 'MesList',
    component: () => import('../views/Message/MesList.vue'),
    meta: {
      title: '消息',
      keepAlive: true,
    }
  },
  {
    path: '/MesDetail',
    name: 'MesDetail',
    component: () => import('../views/Message/MesDetail.vue'),
    meta: {
      title: '消息详情',
    }
  },
  {
    path: '/My',
    name: 'My',
    component: () => import('../views/UserCenter/My.vue'),
    meta: {
      title: '我的',
    }
  },
  {
    path: '/PersonMessage',
    name: 'PersonMessage',
    component: () => import('../views/UserCenter/PersonMessage.vue'),
    meta: {
      title: '基本信息',
    }
  },
  {
    path: '/MyOrder',
    name: 'MyOrder',
    component: () => import('../views/UserCenter/MyOrder.vue'),
    meta: {
      title: '我的报名',
      keepAlive: true,
    }
  },
  {
    path: '/MyClass',
    name: 'MyClass',
    component: () => import('../views/UserCenter/MyClass.vue'),
    meta: {
      title: '报名课程',
    }
  },
  {
    path: '/SignIn',
    name: 'SignIn',
    component: () => import('../views/UserCenter/SignIn.vue'),
    meta: {
      title: '课程签到',
    }
  },
  {
    path: '/SignRecord',
    name: 'SignRecord',
    component: () => import('../views/UserCenter/SignRecord.vue'),
    meta: {
      title: '签到记录',
    }
  },
  {
    path: '/Setting',
    name: 'Setting',
    component: () => import('../views/UserCenter/Setting.vue'),
    meta: {
      title: '设置',
    }
  },
  {
    path: '/ChangePassword',
    name: 'ChangePassword',
    component: () => import('../views/UserCenter/ChangePassword.vue'),
    meta: {
      title: '修改密码',
    }
  },
  {
    path: '/Ysxy',
    name: 'Ysxy',
    component: () => import('../views/UserCenter/Ysxy.vue'),
    meta: {
      title: '隐私协议',
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
